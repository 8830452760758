import { COLORS } from '../../constant';
import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import { ConnectionAccountDetail, JobEntry } from '../types';
import { InfoIcon } from '../../shared/InfoIcon';

import { format } from '../../shared/utils/dateFormatter';

import {
  ConnectionCard,
  ConnectionCardHeader,
  ConnectionCardBody,
  ConnectionCardRow,
} from 'components/ConnectionCard';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import {
  ExternalJobStatus,
  JobType,
} from '@finch-api/common/dist/external/dashboard/job-status';

const NotAvailable = () => (
  <HStack justify="space-between">
    <Text
      fontWeight={400}
      fontSize="14px"
      lineHeight="17px"
      color={COLORS.FINCH.BLACK}
    >
      Not Available
    </Text>
    <Tooltip
      placement="top-end"
      label={'This information is not yet available'}
    >
      <div>
        <InfoIcon />
      </div>
    </Tooltip>
  </HStack>
);

const DateField = ({ date }: { date: Date | string }) => (
  <HStack justify="space-between">
    <Text
      fontWeight={400}
      fontSize="14px"
      lineHeight="17px"
      color={COLORS.FINCH.BLACK}
    >
      {format(new Date(date))}
    </Text>
  </HStack>
);

export const DataSyncInformationCard = ({
  connectionDetail,
  jobs,
}: {
  connectionDetail: ConnectionAccountDetail;
  jobs: JobEntry[];
}) => {
  const opacity =
    connectionDetail.connectionStatus === AccountStatus.DISCONNECTED ? 0.5 : 1;

  const dataSyncJobs =
    connectionDetail.connectionStatus !== AccountStatus.DISCONNECTED
      ? jobs.filter((job) => job.type === JobType.ORG_PAYROLL_SYNC)
      : [];

  // Get the first pending job date
  const nextSyncDate =
    dataSyncJobs
      .filter((job) => job.status === ExternalJobStatus.PENDING)
      .reverse()[0]?.date || null;
  const lastSuccesfulSync =
    dataSyncJobs.filter((job) => job.status === ExternalJobStatus.COMPLETE)[0]
      ?.date || null;

  return (
    <ConnectionCard>
      <ConnectionCardHeader>
        <Text
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          lineHeight="17px"
          color={COLORS.FINCH.BLACK}
        >
          Data Sync Information
        </Text>
      </ConnectionCardHeader>
      <ConnectionCardBody spacing="16px">
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Next Estimated Sync
            </Text>
          </Box>
          <Box opacity={opacity}>
            {nextSyncDate ? (
              <DateField date={nextSyncDate} />
            ) : (
              <NotAvailable />
            )}
          </Box>
        </ConnectionCardRow>
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Last Successful Sync
            </Text>
          </Box>
          <Box opacity={opacity}>
            {lastSuccesfulSync ? (
              <DateField date={lastSuccesfulSync} />
            ) : (
              <NotAvailable />
            )}
          </Box>
        </ConnectionCardRow>
      </ConnectionCardBody>
    </ConnectionCard>
  );
};
