import { ReactNode } from 'react';
import {
  Box,
  Stack,
  HStack,
  Spacer,
  Text,
  Tag,
  Wrap,
  WrapItem,
  Button,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';
import { WebhookEndpoint } from '@finch-api/developer-dashboard-common/dist/api/webhook';
import { WebhookEndpointStatusTag } from '../../components/Tag/WebhookEndpointStatusTag';
import { ContentBox } from '../../components/ContentBox';

const ResponsiveStack = ({ children }: { children: ReactNode }) => {
  return (
    <Stack direction={{ base: 'column', md: 'row' }} align="flex-start">
      {children}
    </Stack>
  );
};

export const WebhookDetail = ({
  endpoint,
  onWebhookEditRequested,
}: {
  endpoint: WebhookEndpoint;
  onWebhookEditRequested: (endpoint: WebhookEndpoint) => void;
}) => {
  return (
    <ContentBox>
      <Box p="6">
        <HStack>
          <Text
            fontWeight={500}
            fontSize="14px"
            lineHeight="17px"
            color={COLORS.FINCH.BLACK}
          >
            Webhook Details
          </Text>
          <Spacer />

          <WebhookEndpointStatusTag
            variant={endpoint.enabled ? 'enabled' : 'disabled'}
          />
        </HStack>
        <Stack direction="column" spacing="24px" marginTop="24px">
          <ResponsiveStack>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.GRAY.GRAY_600}
                width="200px"
              >
                URL
              </Text>
            </Box>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
              >
                {endpoint.url}
              </Text>
            </Box>
          </ResponsiveStack>
          <ResponsiveStack>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.GRAY.GRAY_600}
                width="200px"
              >
                Endpoint ID
              </Text>
            </Box>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
              >
                {endpoint.id}
              </Text>
            </Box>
          </ResponsiveStack>
          <ResponsiveStack>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.GRAY.GRAY_600}
                width="200px"
              >
                Event Types
              </Text>
            </Box>
            <Wrap spacing={3}>
              {endpoint.eventTypes.sort().map((event) => (
                <WrapItem key={event}>
                  <Tag
                    key={event}
                    backgroundColor="#EFF1FF"
                    color={COLORS.FINCH.PURPLE}
                  >
                    {event}
                  </Tag>
                </WrapItem>
              ))}
            </Wrap>
          </ResponsiveStack>
          <Stack direction="row-reverse">
            <Button
              variant="outline"
              size="md"
              onClick={() => onWebhookEditRequested(endpoint)}
            >
              Edit Webhook
            </Button>
          </Stack>
        </Stack>
      </Box>
    </ContentBox>
  );
};
