export const IncompleteCircle = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.75"
      y="2.75"
      width="14.5"
      height="14.5"
      rx="7.25"
      stroke="#737373"
      strokeWidth="1.5"
      strokeDasharray="4 4"
    />
  </svg>
);
