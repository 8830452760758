import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { baseURL } from '../../utils/baseUrls';
import { ConnectSessionEvent, ConnectSession } from 'types/connect';

export type CreateConnectSessionResponse = {
  sessionId: string;
  connectUrl: string;
};

export type CreateConnectSessionError = {
  message: string;
  extra?: { context?: { connectionId?: string } };
};

export const useConnectSessionEvents = ({
  sessionId,
}: {
  sessionId: string;
}) => {
  const { applicationId } = useParams<{
    applicationId: string;
  }>();
  const { getAccessTokenSilently } = useAuth0();

  const list = useQuery({
    queryKey: ['connect-session-events', applicationId, sessionId],
    queryFn: async () => {
      const uri = new URL(
        `/api/v1/applications/${applicationId}/connect-sessions/${sessionId}/events`,
        baseURL,
      );
      return axios
        .get<{ events: ConnectSessionEvent[] }>(uri.toString(), {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        })
        .then((res) => res.data.events);
    },
    initialData: [],
    enabled: !!applicationId && !!sessionId,
  });

  return { list };
};
