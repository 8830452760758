import { Banner } from '../../components/Banner/Banner';
import { COLORS } from 'constant';
import { Link } from '@chakra-ui/react';
import warningInfoIcon from '../../assets/images/icons/warning_info.svg';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import { Connection } from '../model';

type ConnectionAttentionBannerProps = {
  connectionCount: number;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  fullWidth?: boolean;
};

export const connectionsNeedAttention = (connections: Connection[]): number => {
  const needsAttention = [
    AccountStatus.ERROR_NO_ACCOUNT_SETUP,
    AccountStatus.ERROR_PERMISSIONS,
    AccountStatus.REAUTH,
  ];
  return connections.filter((conn) =>
    needsAttention.includes(conn.connectionStatus),
  ).length;
};

export const ConnectionAttentionBanner = (
  props: ConnectionAttentionBannerProps,
): JSX.Element | null => {
  const { connectionCount, onClick } = props;
  if (!connectionCount) return null;
  return (
    <Banner
      icon={warningInfoIcon}
      fullWidth={props.fullWidth}
      content={
        <>
          {`${connectionCount} connections need attention `}
          <Link
            onClick={onClick}
            color={COLORS.ORANGE}
            textDecoration="underline"
            _hover={{
              color: COLORS.FINCH.BLACK,
            }}
          >
            View Now
          </Link>
        </>
      }
    />
  );
};
