import { COLORS } from '../../constant';
import { Box, HStack, Text, useClipboard } from '@chakra-ui/react';
import { ConnectionAccountDetail } from '../types';

import { FiCheckCircle } from 'react-icons/fi';
import { TransparentIconButton } from '../../shared/TransparentIconButton';
import { RefObject } from 'react';
import { CopyIcon } from '../../shared/icons/CopyIcon';
import { format } from '../../shared/utils/dateFormatter';
import { getConnectionGrouping } from '../utils';

import {
  ConnectionCard,
  ConnectionCardHeader,
  ConnectionCardBody,
  ConnectionCardRow,
} from 'components/ConnectionCard';
import { ConnectionType } from './ConnectionType';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';

export const ClipboardButton = ({
  buttonRef,
  value,
}: {
  buttonRef?: RefObject<HTMLButtonElement>;
  value: string;
}) => {
  const { hasCopied, onCopy } = useClipboard(value);
  return hasCopied ? (
    <TransparentIconButton
      ref={buttonRef}
      aria-label="copied"
      icon={<FiCheckCircle />}
      onClick={onCopy}
      variant="icon"
      width="20px"
      height="20px"
    />
  ) : (
    <TransparentIconButton
      ref={buttonRef}
      aria-label="copy"
      icon={<CopyIcon />}
      onClick={onCopy}
      variant="icon"
      width="20px"
      height="20px"
    />
  );
};

export const ConnectionInformationCard = ({
  connectionDetail,
}: {
  connectionDetail: ConnectionAccountDetail;
}) => {
  const opacity =
    connectionDetail.connectionStatus === AccountStatus.DISCONNECTED ? 0.5 : 1;

  const connectionTypes = Array.from(
    new Set(
      getConnectionGrouping(connectionDetail).map((group) => group.category),
    ),
  );

  return (
    <ConnectionCard>
      <ConnectionCardHeader>
        <Text
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          lineHeight="17px"
          color={COLORS.FINCH.BLACK}
        >
          Connection Information
        </Text>
      </ConnectionCardHeader>
      <ConnectionCardBody spacing="16px">
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Connection ID
            </Text>
          </Box>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.FINCH.BLACK}
              opacity={opacity}
            >
              {connectionDetail.connectionAccountId}
              <ClipboardButton value={connectionDetail.connectionAccountId} />
            </Text>
          </Box>
        </ConnectionCardRow>
        {connectionDetail.externalCompanyId && (
          <ConnectionCardRow>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.GRAY.GRAY_600}
                width="200px"
              >
                Customer ID
              </Text>
            </Box>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
                opacity={opacity}
              >
                {connectionDetail.externalCompanyId}
                <ClipboardButton value={connectionDetail.externalCompanyId} />
              </Text>
            </Box>
          </ConnectionCardRow>
        )}
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Connection Type
            </Text>
          </Box>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.FINCH.BLACK}
              opacity={opacity}
            >
              <HStack spacing="16px">
                {connectionTypes.map((type, index) => (
                  <ConnectionType
                    key={index}
                    kind={type}
                    disconnected={false}
                  />
                ))}
              </HStack>
            </Text>
          </Box>
        </ConnectionCardRow>
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Connected At
            </Text>
          </Box>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.FINCH.BLACK}
              opacity={opacity}
            >
              {format(new Date(connectionDetail.firstConnectedAt))}
            </Text>
          </Box>
        </ConnectionCardRow>
      </ConnectionCardBody>
    </ConnectionCard>
  );
};
