import { COLORS } from '../../constant';
import { Box, HStack, Text } from '@chakra-ui/react';
import { StagedConnectionDetail } from '../types';
import { ProviderIcon } from '../../components/Iconagraphy/ProviderIcon';

import {
  ConnectionCard,
  ConnectionCardHeader,
  ConnectionCardBody,
  ConnectionCardRow,
} from '../../components/ConnectionCard';
import { kindToTitle } from 'connection-detail-v2/utils';

export const ProviderInformationCard = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail;
}) => {
  return (
    <ConnectionCard display="flex" flexDirection="column" h="100%">
      <ConnectionCardHeader>
        <Text
          fontWeight="500"
          fontSize="14px"
          lineHeight="17px"
          color={COLORS.FINCH.BLACK}
        >
          Provider Information
        </Text>
      </ConnectionCardHeader>

      {connectionDetail.stagedConnection.providerId ? (
        <ConnectionCardBody spacing="16px">
          <ConnectionCardRow>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.GRAY.GRAY_600}
                width="200px"
              >
                Provider Name
              </Text>
            </Box>
            <Box>
              <HStack>
                <ProviderIcon
                  src={connectionDetail.stagedConnection.providerIcon}
                  provider={connectionDetail.stagedConnection.providerName}
                />
                <Text
                  fontWeight={400}
                  fontSize="14px"
                  lineHeight="17px"
                  color={COLORS.FINCH.BLACK}
                >
                  {connectionDetail.stagedConnection.providerName}
                </Text>
              </HStack>
            </Box>
          </ConnectionCardRow>
          {connectionDetail.stagedConnection.implementationKind && (
            <ConnectionCardRow>
              <Box>
                <Text
                  fontWeight={400}
                  fontSize="14px"
                  lineHeight="17px"
                  color={COLORS.GRAY.GRAY_600}
                  width="200px"
                >
                  Integration Type
                </Text>
              </Box>
              <Box>
                <Text
                  fontWeight={400}
                  fontSize="14px"
                  lineHeight="17px"
                  color={COLORS.FINCH.BLACK}
                >
                  {
                    kindToTitle[
                      connectionDetail.stagedConnection.implementationKind
                    ]
                  }
                </Text>
              </Box>
            </ConnectionCardRow>
          )}
        </ConnectionCardBody>
      ) : (
        <ConnectionCardRow py="4" flex={1} justify="center" align="center">
          <Text
            fontWeight={400}
            fontSize="14px"
            lineHeight="17px"
            color={COLORS.GRAY.GRAY_600}
          >
            No provider has been selected by the employer
          </Text>
        </ConnectionCardRow>
      )}
    </ConnectionCard>
  );
};
