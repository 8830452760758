import styled from '@emotion/styled';
import { useState } from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Box,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  flexRender,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import type { Row, PaginationState } from '@tanstack/react-table';
import { COLORS } from '../../constant';
import { NoItemsScreen } from './NoItemsScreen';

import { TableHeaderContent } from '../../components/TableHeaderContent';
import { CellWithClipboard, TextCell } from './TableCells';
import { format } from '../../shared/utils/dateFormatter';

import { useConnectSession } from '../../connections-v2/hooks/useConnectSession';
import { ConnectSession } from 'types/connect';
import { filterFns } from 'components/TableFilters';
import { ContentBox } from 'components/ContentBox';
import { Pagination } from 'components/Pagination';
import { ConnectSessionStatusTag } from 'components/Tag/ConnectSessionTag';
import { SessionDetailsDrawer } from './SessionDetailsDrawer';
import { useConnectSessionEvents } from '../hooks/useConnectSessionEvents';

const TableCellText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableBodyContent = ({
  rows,
  setSelectedSession,
  onOpen,
}: {
  rows: Row<ConnectSession>[];
  onOpen: () => void;
  setSelectedSession: (session: ConnectSession) => void;
}) => {
  return (
    <>
      {rows.map((row) => {
        return (
          <Box as={Tr} _hover={{ bg: COLORS.GRAY.GRAY_100 }} key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                style={cell.column.columnDef.meta?.style}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                onClick={() => {
                  setSelectedSession(row.original);
                  onOpen();
                }}
                cursor="pointer"
              >
                <TableCellText>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellText>
              </Td>
            ))}
          </Box>
        );
      })}
    </>
  );
};

export const SessionDetailsDrawerWithApi = ({
  connectSession,
  isOpen,
  onClose,
}: {
  connectSession: ConnectSession;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { list } = useConnectSessionEvents({ sessionId: connectSession.id });
  const sessionEvents = list.data ?? [];

  return (
    <SessionDetailsDrawer
      connectSession={connectSession}
      isOpen={isOpen}
      onClose={onClose}
      sessionEvents={sessionEvents}
      isLoading={list.isLoading}
    />
  );
};

export const ConnectSessions = () => {
  const columnHelper = createColumnHelper<ConnectSession>();

  const { list } = useConnectSession();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = [
    columnHelper.accessor('id', {
      header: 'Session ID',
      cell: (props) => (
        <CellWithClipboard
          text={props.getValue()}
          textProps={{
            maxW: '250px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          clipboardValue={props.getValue()}
        />
      ),
    }),
    columnHelper.accessor('url', {
      header: 'Connect URL',
      cell: (props) => (
        <CellWithClipboard
          text={props.getValue()}
          textProps={{
            maxW: '150px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          clipboardValue={props.getValue()}
        />
      ),
      meta: {
        style: { width: '200px' },
      },
    }),
    columnHelper.accessor('createdAt', {
      header: 'Date Created',
      cell: (props) => <TextCell text={format(new Date(props.getValue()))} />,
    }),
    columnHelper.accessor('expiresAt', {
      header: 'Expiration Date',
      cell: (props) => {
        const value = props.getValue();
        return <TextCell text={value ? format(new Date(value)) : '-'} />;
      },
    }),
    columnHelper.accessor(
      (row) => {
        if (row.tokenId) return 'complete';
        if (new Date(row.expiresAt) < new Date()) return 'expired';
        return 'pending';
      },
      {
        id: 'status',
        header: 'Status',
        cell: (props) => <ConnectSessionStatusTag variant={props.getValue()} />,
      },
    ),
  ];

  const {
    getRowModel,
    getHeaderGroups,
    getCanPreviousPage,
    getCanNextPage,
    getState,
  } = useReactTable({
    data: list.data,
    columns,
    state: {
      pagination: { pageIndex, pageSize },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns,
  });

  const state = getState();
  const rows = getRowModel().rows;
  const headerGroups = getHeaderGroups();
  const [selectedSession, setSelectedSession] = useState<ConnectSession | null>(
    null,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Stack>
      <ContentBox overflowX="scroll">
        {list.data?.length ? (
          <TableContainer height="fit-content" style={{ overflow: 'visible' }}>
            <Box overflowX="scroll">
              <Table variant="simple">
                <TableHeaderContent
                  thOverrideStyle={{ p: '10px 20px' }}
                  headerGroups={headerGroups}
                />
                <Tbody borderTop={`1px solid ${COLORS.GRAY.GRAY_400}`}>
                  <TableBodyContent
                    rows={rows}
                    onOpen={onOpen}
                    setSelectedSession={setSelectedSession}
                  />
                </Tbody>
              </Table>
            </Box>
          </TableContainer>
        ) : (
          <NoItemsScreen header="No Connect Sessions found" message="" />
        )}
      </ContentBox>
      {list.data?.length && (
        <Box paddingTop={2}>
          <Pagination
            previousPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex - 1,
              }));
            }}
            canPreviousPage={getCanPreviousPage()}
            nextPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex + 1,
              }));
            }}
            canNextPage={getCanNextPage()}
            pageIndex={state.pagination.pageIndex}
            pageSize={state.pagination.pageSize}
            totalSize={rows.length}
          />
        </Box>
      )}

      {isOpen && selectedSession && (
        <SessionDetailsDrawerWithApi
          connectSession={selectedSession}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </Stack>
  );
};
