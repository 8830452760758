import { COLORS } from 'constant';
import { ChevronDownIcon } from 'shared/icons/ChevronDownIcon';
import { LinkIcon } from 'shared/icons/Link';
import {
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { ModalNewConnectSession } from './ConnectSession/ModalNewConnectSession';

const ConnectionMenuItem = ({
  icon,
  text,
  color = COLORS.FINCH.GRAY,
  hoverColor = COLORS.FINCH.BLACK,
  onClick,
}: {
  icon: IconType;
  text: string;
  color?: string;
  hoverColor?: string;
  onClick?: () => void;
}) => {
  return (
    <MenuItem
      _hover={{
        backgroundColor: hoverColor,
        borderRadius: '4px',
        color,
      }}
      onClick={onClick}
    >
      <Flex gap={1} color={color}>
        <HStack spacing="8px">
          <Icon as={icon} color={color} />
          <Text fontSize={'16px'}>{text}</Text>
        </HStack>
      </Flex>
    </MenuItem>
  );
};

export const ConnectionsActionMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          aria-label="Actions"
          rightIcon={<Icon as={ChevronDownIcon} color="white"></Icon>}
          size="md"
          variant="primaryPurple"
        >
          Actions
        </MenuButton>
        <MenuList minWidth="200px" borderRadius={'8px'}>
          <ConnectionMenuItem
            icon={LinkIcon}
            hoverColor={COLORS.GRAY.GRAY_200}
            text="Create a New Connect Link"
            onClick={onOpen}
          />
        </MenuList>
      </Menu>
      {isOpen && <ModalNewConnectSession isOpen={isOpen} onClose={onClose} />}
    </>
  );
};
