export type ConnectSession = {
  clientId: string;
  clientName: string | null;
  connectionId: string | null;
  createdAt: Date;
  expiresAt: Date;
  externalCompanyEmail: string | null;
  externalCompanyId: string | null;
  externalCompanyName: string | null;
  id: string;
  implementationId: string | null;
  providerId: string | null;
  redirectUri: string;
  sandbox: string | null;
  scopes: string[];
  showAssisted: boolean;
  tokenId: string | null;
  updatedAt: Date;
  url: string;
};

export enum ConnectStep {
  PREAMBLE = 'PREAMBLE',
  PERMISSIONS = 'PERMISSIONS',
  SELECT_PROVIDER = 'SELECT_PROVIDER',
  SIGN_IN = 'SIGN_IN',
  MANUAL_SIGN_IN = 'MANUAL_SIGN_IN',
  MFA = 'MFA',
  CAPTCHA = 'CAPTCHA',
  CHOOSE_ACCOUNT = 'CHOOSE_ACCOUNT',
}

export enum ConnectSessionEventType {
  OnError = 'on_error',
  OnSessionCompleted = 'on_session_completed',
  OnStepReached = 'on_step_reached',
}

export type TransientSessionInfo = {
  acfFallbackEligible?: boolean;
  payrollProviderId?: string;
  providerImplementationId?: string;
  state?: string;
  step?: string;
  appType?: string;
  currentBrowserSessionKey?: string;
};

export type ConnectSessionBaseEvent = {
  connectSessionId: string;
  createdAt: Date;
  id: string;
  providerIcon?: string | null;
  providerId?: string | null;
  providerLogo?: string | null;
  providerName?: string | null;
};

export type ConnectSessionErrorEvent = ConnectSessionBaseEvent & {
  eventData: {
    error: unknown;
    errorMessage: string;
    errorStr: string;
    session: TransientSessionInfo;
    step: string;
  };
  eventType: ConnectSessionEventType.OnError;
};

export type ConnectSessionOnStepReachedEvent = ConnectSessionBaseEvent & {
  eventData: {
    session: TransientSessionInfo;
    step: string;
  };
  eventType: ConnectSessionEventType.OnStepReached;
};

export type ConnectSessionSessionCompletedEvent = ConnectSessionBaseEvent & {
  eventData: {
    session: TransientSessionInfo;
    step: string;
  };
  eventType: ConnectSessionEventType.OnSessionCompleted;
};

export type ConnectSessionEvent =
  | ConnectSessionErrorEvent
  | ConnectSessionOnStepReachedEvent
  | ConnectSessionSessionCompletedEvent;

export type CreateConnectSessionResponse = {
  sessionId: string;
  connectUrl: string;
};

export type CreateConnectSessionError = {
  message: string;
  extra?: { context?: { connectionId?: string } };
};
