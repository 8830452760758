import { COLORS } from 'constant';
import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { ConnectionsTable } from './ConnectionsTable';
import { StagedConnectionsTable } from './StagedConnectionsTable';
import { Connection } from '../model';
import {
  ConnectionAttentionBanner,
  connectionsNeedAttention,
} from './ConnectionAttentionBanner';
import { useRef, useState } from 'react';
import { StagedConnection } from '../types';
import {
  ConnectionStage,
  useConnectionStageQuerySync,
} from '../hooks/useConnectionStageSync';

export const AllConnectionsTable = ({
  connections,
  stagedConnections,
  loading,
  isSandbox,
  error,
  stagedError,
  stagedLoading,
}: {
  connections: Connection[];
  stagedConnections: StagedConnection[];
  isSandbox?: boolean;
  loading: boolean;
  error: Error | null;
  stagedError: Error | null;
  stagedLoading: boolean;
}) => {
  const connectionCount = connectionsNeedAttention(connections);
  const connectionsTableRef = useRef<HTMLDivElement>(null);
  const [filterToNeedsAttention, setFilterToNeedsAttention] =
    useState<boolean>(false);

  const scrollToConnectionsTable = () => {
    connectionsTableRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const [connectionStage, setConnectionStage] = useConnectionStageQuerySync();

  const filterAndScroll = () => {
    setFilterToNeedsAttention(true);
    scrollToConnectionsTable();
  };

  return (
    <Tabs
      flexGrow={1}
      index={connectionStage === ConnectionStage.Complete ? 0 : 1}
      onChange={(index) =>
        setConnectionStage(
          index === 0 ? ConnectionStage.Complete : ConnectionStage.Incomplete,
        )
      }
    >
      <TabList gap={6}>
        {['Completed', 'Incomplete'].map((title) => (
          <Tab
            key={title}
            _selected={{
              color: COLORS.FINCH.BLACK,
              fontWeight: 'semibold',
              borderColor: COLORS.FINCH.BLACK,
            }}
            fontSize="14px"
            color={COLORS.GRAY.GRAY_600}
            padding="4px 4px 12px 4px"
          >
            {title}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        <TabPanel paddingLeft="0" paddingRight="0">
          <Stack spacing={4}>
            <ConnectionAttentionBanner
              connectionCount={connectionCount}
              onClick={filterAndScroll}
              fullWidth={true}
            />
            <div ref={connectionsTableRef}>
              <ConnectionsTable
                connections={connections}
                loading={loading}
                isSandbox={isSandbox}
                error={error}
                filterToNeedsAttention={filterToNeedsAttention}
                setFilterToNeedsAttention={setFilterToNeedsAttention}
              />
            </div>
          </Stack>
        </TabPanel>
        <TabPanel paddingLeft="0" paddingRight="0">
          <StagedConnectionsTable
            connections={stagedConnections}
            loading={stagedLoading}
            isSandbox={isSandbox}
            error={stagedError}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
