import { Badge, BadgeTheme } from '../Badge/Badge';
import check_circle from '../../assets/images/icons/check_circle.svg';
import in_progress from '../../assets/images/icons/in_progress.svg';
import error_warning_red from '../../assets/images/icons/error_warning_red.svg';
import pending from '../../assets/images/icons/pending.svg';
import disconnected from '../../assets/images/icons/disconnected.svg';

const connectStepVariants = {
  PREAMBLE: {
    icon: check_circle,
    theme: BadgeTheme.GREEN,
    text: 'Terms of service',
  },
  PERMISSIONS: {
    icon: check_circle,
    theme: BadgeTheme.GREEN,
    text: 'Permissions',
  },
  PROVIDER_SELECT: {
    icon: check_circle,
    theme: BadgeTheme.GREEN,
    text: 'Provider Selection',
  },
  SIGN_IN: {
    icon: in_progress,
    theme: BadgeTheme.BLUE,
    text: 'Authorization',
  },
  MFA: {
    icon: in_progress,
    theme: BadgeTheme.BLUE,
    text: 'Authorization',
  },
  CAPTCHA: {
    icon: in_progress,
    theme: BadgeTheme.BLUE,
    text: 'Authorization',
  },
  CHOOSE_ACCOUNT: {
    icon: in_progress,
    theme: BadgeTheme.BLUE,
    text: 'Authorization',
  },
  NOT_STARTED: {
    icon: pending,
    theme: BadgeTheme.GRAY,
    text: 'Not Started',
  },
  EXPIRED: {
    icon: disconnected,
    theme: BadgeTheme.GRAY,
    text: 'Session Expired',
  },
};

type ConnectStepVariant = keyof typeof connectStepVariants;

export const ConnectStepReachedTag = ({
  variant,
  isError,
}: {
  variant: ConnectStepVariant;
  isError?: boolean;
}) => {
  const { icon, theme, text } = connectStepVariants[variant];

  return (
    <Badge
      theme={isError ? BadgeTheme.RED :   theme}
      icon={isError ? error_warning_red :  icon}
      text={ text}
    />
  );
};
