import { COLORS } from '../../constant';
import {
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { SandboxMode } from '../types';
import { useConnections, useSandboxConnections } from '../hooks/useConnections';
import { SandboxInfoBar } from './SandboxInfoBar';
import { PageHeading } from '../../shared/Typography';
import { usePermissions } from '../../hooks/use-permissions';
import { PERMISSION } from '../../constant/roles-and-permissions';
import { AllConnectionsTable } from './AllConnectionsTable';
import { ConnectionsActionMenu } from './ConnectionActionMenu';

const tabIndexToSandboxMode: Array<SandboxMode> = ['finch', 'provider'];

const SandboxConnections = ({
  loading,
  error,
  stagedError,
  stagedLoading,
}: {
  loading: boolean;
  error: Error | null;
  stagedError: Error | null;
  stagedLoading: boolean;
}) => {
  const {
    limit,
    used,
    connections,
    selectedSandbox,
    setSelectedSandbox,
    stagedConnections,
  } = useSandboxConnections();

  return (
    <Tabs
      size="sm"
      isLazy
      index={tabIndexToSandboxMode.indexOf(selectedSandbox)}
      onChange={(index) =>
        setSelectedSandbox(tabIndexToSandboxMode[index] || 'finch')
      }
    >
      <TabList>
        <Tab color={COLORS.FINCH.BLACK}>Finch Sandbox</Tab>
        <Tab color={COLORS.FINCH.BLACK}>Provider Sandbox</Tab>
      </TabList>
      <SandboxInfoBar
        limit={limit}
        used={used}
        loading={loading}
        mode={selectedSandbox}
      >
        <TabPanels>
          <TabPanel paddingLeft="0" paddingRight="0">
            <AllConnectionsTable
              connections={connections}
              stagedConnections={stagedConnections}
              loading={loading}
              isSandbox
              error={error}
              stagedError={stagedError}
              stagedLoading={stagedLoading}
            />
          </TabPanel>
          <TabPanel paddingLeft="0" paddingRight="0">
            <AllConnectionsTable
              connections={connections}
              stagedConnections={stagedConnections}
              loading={loading}
              isSandbox
              error={error}
              stagedError={stagedError}
              stagedLoading={stagedLoading}
            />
          </TabPanel>
        </TabPanels>
      </SandboxInfoBar>
    </Tabs>
  );
};

export const ConnectionsScreen = ({ isSandbox }: { isSandbox: boolean }) => {
  const {
    isLoading,
    connections,
    error,
    stagedError,
    stagedConnections,
    isStagedLoading,
  } = useConnections();

  const roleCanCreateConnectSession = usePermissions([
    PERMISSION.ConnectSession.Create,
  ]);

  return (
    <Stack spacing="6">
      <HStack justify="space-between">
        <PageHeading color={COLORS.FINCH.BLACK} fontSize="24px" flexGrow={1}>
          {!isSandbox ? 'Connections' : 'Sandbox Connections'}
        </PageHeading>
        <Stack>
          {roleCanCreateConnectSession && <ConnectionsActionMenu />}
        </Stack>
      </HStack>

      {!isSandbox ? (
        <AllConnectionsTable
          connections={connections}
          stagedConnections={stagedConnections}
          loading={isLoading}
          error={error}
          stagedError={stagedError}
          stagedLoading={isStagedLoading}
        />
      ) : (
        <SandboxConnections
          loading={isLoading}
          error={error}
          stagedError={stagedError}
          stagedLoading={isStagedLoading}
        />
      )}
    </Stack>
  );
};
