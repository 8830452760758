import { ConnectionCardRow } from 'components/ConnectionCard';
import { LuMilestone } from 'react-icons/lu';
import {
  Box,
  DrawerCloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  StackDivider,
  Center,
  Spinner,
} from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { COLORS } from '../../constant';

import { ClipboardButton } from './ConnectionInformationCard';
import { ScopeTag } from 'components/ScopeTag';
import { format } from '../../shared/utils/dateFormatter';
import {
  ConnectSessionEventType,
  ConnectStep,
  ConnectSession,
  ConnectSessionEvent,
} from 'types/connect';
import { CancelCircleIcon } from 'shared/icons/CancelCircle';
import { CheckCircle } from 'shared/icons/CheckCircle';
import { ReactNode } from 'react';

export const ConnectStepMap: Record<ConnectStep, string> = {
  [ConnectStep.PERMISSIONS]: 'Permissions',
  [ConnectStep.SELECT_PROVIDER]: 'Provider Selection',
  [ConnectStep.PREAMBLE]: 'Terms of service',
  [ConnectStep.SIGN_IN]: 'Sign In',
  [ConnectStep.MANUAL_SIGN_IN]: 'Assisted connect flow',
  [ConnectStep.MFA]: 'Multifactor Authentication',
  [ConnectStep.CAPTCHA]: 'Captcha',
  [ConnectStep.CHOOSE_ACCOUNT]: 'Choose Account',
};

const SessionDetailItem = ({
  data,
  withClipboard,
  title,
}: {
  data: ReactNode;
  title: string;
  withClipboard?: boolean;
}) => {
  return (
    <ConnectionCardRow px="0">
      <Text
        fontWeight={400}
        flex="2"
        fontSize="14px"
        lineHeight="17px"
        color={COLORS.GRAY.GRAY_600}
        width="200px"
      >
        {title}
      </Text>

      <Box flex="5">
        {typeof data === 'string' ? (
          <Text
            fontWeight={400}
            fontSize="14px"
            lineHeight="17px"
            color={COLORS.FINCH.BLACK}
          >
            {data}
            {withClipboard && <ClipboardButton value={data} />}
          </Text>
        ) : (
          data
        )}
      </Box>
    </ConnectionCardRow>
  );
};

export function SessionDetailsDrawer({
  connectSession,
  sessionEvents,
  isOpen,
  onClose,
  isLoading,
}: {
  connectSession: ConnectSession;
  sessionEvents: ConnectSessionEvent[];
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
}) {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent bg={COLORS.GRAY.GRAY_100}>
        <HStack
          p="4"
          justify="space-between"
          bg={COLORS.GRAY.GRAY_100}
          align="center"
          borderBottom={`1px solid ${COLORS.GRAY.GRAY_400}`}
        >
          <Heading fontWeight="600" fontSize="14px">
            Session details
          </Heading>
          <DrawerCloseButton pos="relative" top="0" right="0" />
        </HStack>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Stack>
            <Tabs size="sm" variant="enclosed" colorScheme="green">
              <TabList p="4" gap={4}>
                <Tab
                  borderRadius="4px"
                  borderColor={COLORS.GRAY.GRAY_500}
                  color={COLORS.GRAY.GRAY_600}
                  fontSize="xs"
                  fontWeight={500}
                  _selected={{
                    bg: COLORS.FINCH.LIGHT_PURPLE,
                    borderColor: COLORS.FINCH.PURPLE,
                    color: COLORS.FINCH.PURPLE,
                  }}
                >
                  Details
                </Tab>
                <Tab
                  borderRadius="4px"
                  borderColor={COLORS.GRAY.GRAY_500}
                  fontSize="xs"
                  color={COLORS.GRAY.GRAY_600}
                  fontWeight={500}
                  _selected={{
                    bg: COLORS.FINCH.LIGHT_PURPLE,
                    borderColor: COLORS.FINCH.PURPLE,
                    color: COLORS.FINCH.PURPLE,
                  }}
                >
                  Session events
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack
                    spacing="6"
                    divider={
                      <StackDivider borderColor={COLORS.GRAY.GRAY_300} />
                    }
                  >
                    {connectSession.externalCompanyName && (
                      <SessionDetailItem
                        title="Company Name"
                        data={connectSession.externalCompanyName ?? ''}
                        withClipboard
                      />
                    )}

                    {connectSession.externalCompanyEmail && (
                      <SessionDetailItem
                        title="Company Email"
                        data={connectSession.externalCompanyEmail}
                        withClipboard
                      />
                    )}

                    {connectSession.externalCompanyId && (
                      <SessionDetailItem
                        title="Company ID"
                        data={connectSession.externalCompanyId}
                        withClipboard
                      />
                    )}

                    <SessionDetailItem
                      title="Scopes"
                      data={
                        <HStack wrap="wrap">
                          {connectSession.scopes.map((type) => (
                            <ScopeTag key={type} scope={type} />
                          ))}
                        </HStack>
                      }
                    />

                    <SessionDetailItem
                      title="Date Created"
                      data={format(new Date(connectSession.createdAt))}
                    />
                    <SessionDetailItem
                      title="Expiration Date"
                      data={format(new Date(connectSession.expiresAt))}
                    />
                    <SessionDetailItem
                      title="Connect URL"
                      data={connectSession.url}
                      withClipboard
                    />

                    <SessionDetailItem
                      title="Session ID"
                      data={connectSession.id}
                      withClipboard
                    />

                    <SessionDetailItem
                      title="Redirect URL"
                      data={connectSession.redirectUri}
                      withClipboard
                    />

                    {connectSession.sandbox && (
                      <SessionDetailItem
                        title="Sandbox"
                        data={connectSession.sandbox}
                      />
                    )}
                  </Stack>
                </TabPanel>
                <TabPanel>
                  {!sessionEvents.length && (
                    <Text fontSize="sm" color={COLORS.GRAY.GRAY_600}>
                      The employer has not started the Connect flow
                    </Text>
                  )}
                  <Stack position="relative" spacing="4">
                    <Box
                      position="absolute"
                      top="0"
                      left="5"
                      borderRight="1px solid"
                      borderColor={COLORS.GRAY.GRAY_400}
                      h="100%"
                    />
                    {sessionEvents.map((event) => (
                      <HStack
                        key={event.id}
                        border="1px solid"
                        borderColor={COLORS.GRAY.GRAY_400}
                        borderRadius="4px"
                        p="2"
                        zIndex="1"
                        bg={COLORS.WHITE}
                        align="flex-start"
                      >
                        {event.eventType ===
                          ConnectSessionEventType.OnStepReached && (
                          <Box
                            bg={COLORS.LIGHT_GREEN}
                            borderRadius="full"
                            boxSize={6}
                            p="1"
                          >
                            <LuMilestone color={COLORS.GREEN} />
                          </Box>
                        )}
                        {event.eventType ===
                          ConnectSessionEventType.OnError && (
                          <Box boxSize="6" py="1">
                            <CancelCircleIcon size={20} />
                          </Box>
                        )}
                        {event.eventType ===
                          ConnectSessionEventType.OnSessionCompleted && (
                          <Box boxSize="6" py="1">
                            <CheckCircle size={20} />
                          </Box>
                        )}
                        <Stack flex="1">
                          {event.eventType ===
                            ConnectSessionEventType.OnStepReached && (
                            <Text fontSize="sm" fontWeight={500}>
                              {event.providerName ? (
                                <>
                                  Completed{' '}
                                  {
                                    ConnectStepMap[
                                      event.eventData?.session
                                        ?.step as ConnectStep
                                    ]
                                  }{' '}
                                  and navigated to{' '}
                                  {
                                    ConnectStepMap[
                                      event.eventData?.step as ConnectStep
                                    ]
                                  }
                                </>
                              ) : (
                                ConnectStepMap[
                                  event.eventData?.step as ConnectStep
                                ] || startCase(event.eventData?.session?.step)
                              )}
                            </Text>
                          )}
                          {event.eventType ===
                            ConnectSessionEventType.OnSessionCompleted && (
                            <Text fontSize="sm" fontWeight={500}>
                              {event.providerName ? (
                                <>
                                  Session successfully completed after{' '}
                                  {
                                    ConnectStepMap[
                                      event.eventData?.session
                                        ?.step as ConnectStep
                                    ]
                                  }{' '}
                                  step
                                </>
                              ) : (
                                ConnectStepMap[
                                  event.eventData?.session?.step as ConnectStep
                                ] || startCase(event.eventData?.session?.step)
                              )}
                            </Text>
                          )}
                          {event.eventType ===
                            ConnectSessionEventType.OnError && (
                            <Text fontSize="sm" fontWeight={500}>
                              Error encountered: {event.eventData?.errorMessage}
                            </Text>
                          )}
                          <HStack justify="space-between">
                            {event.providerName ? (
                              <Text fontSize="xs">{event.providerName}</Text>
                            ) : (
                              <Box />
                            )}
                            <Text alignSelf="flex-end" fontSize="xs">
                              {format(new Date(event.createdAt))}
                            </Text>
                          </HStack>
                        </Stack>
                      </HStack>
                    ))}
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        )}
      </DrawerContent>
    </Drawer>
  );
}
