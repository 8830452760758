import {
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import type { IconType } from 'react-icons';
import { LinkIcon } from 'shared/icons/Link';
import { ModalNewConnectSession } from 'connections-v2/components/ConnectSession/ModalNewConnectSession';

import { COLORS } from '../../constant';
import { ChevronDownIcon } from '../../shared/icons/ChevronDownIcon';
import { StagedConnectionDetail } from '../types';
import { useStagedConnectSession } from '../hooks/useStagedConnectSession';

const ConnectionMenuItem = ({
  icon,
  text,
  color = COLORS.FINCH.GRAY,
  hoverColor = COLORS.FINCH.BLACK,
  onClick,
}: {
  icon: IconType;
  text: string;
  color?: string;
  hoverColor?: string;
  onClick?: () => void;
}) => {
  return (
    <MenuItem
      _hover={{
        backgroundColor: hoverColor,
        borderRadius: '4px',
        color,
      }}
      onClick={onClick}
    >
      <Flex gap={1} color={color}>
        <HStack spacing="8px">
          <Icon as={icon} color={color} />
          <Text fontSize={'16px'}>{text}</Text>
        </HStack>
      </Flex>
    </MenuItem>
  );
};
export const ConnectionDetailActionMenu = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail | null;
}) => {
  const {
    isOpen: isNewConnectSessionOpen,
    onOpen: onNewConnectSessionOpen,
    onClose: onNewConnectSessionClose,
  } = useDisclosure();

  const {
    create: { mutate, data, isPending },
  } = useStagedConnectSession();

  const onCreateNewConnectSession = () => {
    if (connectionDetail) {
      mutate(connectionDetail.connectSession, {
        onSuccess: onNewConnectSessionOpen,
      });
    }
  };

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          aria-label="Actions"
          rightIcon={<Icon as={ChevronDownIcon} color="white"></Icon>}
          size="md"
          variant="primaryPurple"
          isLoading={isPending}
        >
          Actions
        </MenuButton>
        <MenuList minWidth="200px" borderRadius={'8px'}>
          <ConnectionMenuItem
            icon={LinkIcon}
            hoverColor={COLORS.GRAY.GRAY_200}
            text="Refresh Connect link"
            onClick={onCreateNewConnectSession}
          />
        </MenuList>
      </Menu>

      {data?.connectUrl && (
        <ModalNewConnectSession
          isOpen={isNewConnectSessionOpen}
          onClose={onNewConnectSessionClose}
          connectUrl={data.connectUrl}
          helperText="This URL is single-use and expires after 14 days."
        />
      )}
    </>
  );
};
