import styled from '@emotion/styled';
import { useCallback, useMemo, useState } from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Box,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  flexRender,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import type { Row, PaginationState } from '@tanstack/react-table';
import { COLORS } from '../../constant';
import { NoItemsScreen } from './NoItemsScreen';

import { TableHeaderContent } from '../../components/TableHeaderContent';
import { TextCell } from './TableCells';
import { format } from '../../shared/utils/dateFormatter';

import { ConnectSession } from 'types/connect';
import { filterFns } from 'components/TableFilters';
import { ContentBox } from 'components/ContentBox';
import { Pagination } from 'components/Pagination';
import { ConnectSessionStatusTag } from 'components/Tag/ConnectSessionTag';
import { StagedConnectionDetail } from '../types';
import { SessionDetailsDrawer } from 'connection-detail-v2/components/SessionDetailsDrawer';

const TableCellText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableBodyContent = ({
  rows,
  onDrawerOpen,
}: {
  rows: Row<ConnectSession>[];
  onDrawerOpen: () => void;
}) => {
  return (
    <>
      {rows.map((row) => {
        return (
          <Tr _hover={{ bg: COLORS.GRAY.GRAY_100 }} key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                style={cell.column.columnDef.meta?.style}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                cursor="pointer"
                onClick={onDrawerOpen}
              >
                <TableCellText>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellText>
              </Td>
            ))}
          </Tr>
        );
      })}
    </>
  );
};

export const ConnectSessionsTable = ({
  connectionDetail,
  onDrawerOpen,
}: {
  onDrawerOpen: () => void;
  connectionDetail: StagedConnectionDetail;
}) => {
  const columnHelper = createColumnHelper<ConnectSession>();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const memoedOnDrawerOpen = useCallback(onDrawerOpen, [onDrawerOpen]);

  const columns = [
    columnHelper.accessor('id', {
      header: 'Session ID',
      cell: (props) => <TextCell text={props.getValue()} />,
    }),
    columnHelper.accessor('createdAt', {
      header: 'Date Created',
      cell: (props) => <TextCell text={format(new Date(props.getValue()))} />,
    }),
    columnHelper.accessor('expiresAt', {
      header: 'Expiration Date',
      cell: (props) => {
        const value = props.getValue();
        return <TextCell text={value ? format(new Date(value)) : '-'} />;
      },
    }),
    columnHelper.accessor(
      (row) => {
        if (row.tokenId) return 'complete';
        if (new Date(row.expiresAt) < new Date()) return 'expired';
        return 'pending';
      },
      {
        id: 'status',
        header: 'Status',
        cell: (props) => <ConnectSessionStatusTag variant={props.getValue()} />,
      },
    ),
  ];

  const connectSessions = useMemo(
    () => [connectionDetail.connectSession],
    [connectionDetail.connectSession],
  );

  const {
    getRowModel,
    getHeaderGroups,
    getCanPreviousPage,
    getCanNextPage,
    getState,
  } = useReactTable({
    data: connectSessions,
    columns,
    state: {
      pagination: { pageIndex, pageSize },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns,
  });

  const state = getState();
  const rows = getRowModel().rows;
  const headerGroups = getHeaderGroups();

  return (
    <Stack>
      <ContentBox overflowX="scroll">
        {connectSessions.length ? (
          <TableContainer height="fit-content" style={{ overflow: 'visible' }}>
            <Box overflowX="scroll">
              <Table variant="simple">
                <TableHeaderContent
                  thOverrideStyle={{ p: '10px 20px' }}
                  headerGroups={headerGroups}
                />
                <Tbody borderTop={`1px solid ${COLORS.GRAY.GRAY_400}`}>
                  <TableBodyContent
                    rows={rows}
                    onDrawerOpen={memoedOnDrawerOpen}
                  />
                </Tbody>
              </Table>
            </Box>
          </TableContainer>
        ) : (
          <NoItemsScreen header="No Connect Sessions found" message="" />
        )}
      </ContentBox>
      {connectSessions.length && (
        <Box paddingTop={2}>
          <Pagination
            previousPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex - 1,
              }));
            }}
            canPreviousPage={getCanPreviousPage()}
            nextPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex + 1,
              }));
            }}
            canNextPage={getCanNextPage()}
            pageIndex={state.pagination.pageIndex}
            pageSize={state.pagination.pageSize}
            totalSize={rows.length}
          />
        </Box>
      )}
    </Stack>
  );
};

export const ConnectSessions = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ConnectSessionsTable
        connectionDetail={connectionDetail}
        onDrawerOpen={onOpen}
      />
      {isOpen && (
        <SessionDetailsDrawer
          isOpen={isOpen}
          onClose={onClose}
          connectSession={connectionDetail.connectSession}
          sessionEvents={connectionDetail.connectSessionEvents}
        />
      )}
    </>
  );
};
