import { useMemo } from 'react';
import {
  Button,
  Checkbox,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';
import debounce from 'lodash/debounce';
import head from 'lodash/head';
import last from 'lodash/last';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import { CheckIcon } from '../../shared/icons/CheckIcon';
import { ChevronDownIcon } from '../../shared/icons/ChevronDownIcon';
import { CalendarContainer } from '../../connections/components/DatePickerTheme';
import { COLORS } from '../../constant';
import { isArrayId, isStringId } from './FilterComposer';
import { getTimeValueForSelectedDateRange } from './filterFunctions';
import {
  FilterInput,
  DateRange,
  SelectFilterValue,
  RadioFilterWithArrayIdValue,
  DateFilterValue,
  RadioFilterWithSingleIdValue,
  SearchFilterValue,
  RelativeDateFilterValue,
  RelativeDateRange,
  RelativeDateUnit,
} from './types';

const scrollShadow = {
  maxHeight: '200px',
  overflow: 'auto',

  background: `
      /* Shadow Cover TOP */
      linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ) center top,
      
      /* Shadow Cover BOTTOM */
      linear-gradient(
        rgba(255, 255, 255, 0), 
        white 70%
      ) center bottom,
      
      /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center top,
      
      /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center bottom`,

  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 20px, 100% 20px, 100% 8px, 100% 8px',
  backgroundAttachment: 'local, local, scroll, scroll',
};

export const SearchFilterInput: FilterInput<SearchFilterValue> = ({
  placeholder,
  value,
  setValue,
}) => (
  <Input
    borderRadius="8px"
    minW="400px"
    size="sm"
    placeholder={placeholder}
    value={value ?? ''}
    onChange={(e) => {
      setValue(e.target.value ?? undefined);
    }}
  />
);

export const SelectFilterInput: FilterInput<SelectFilterValue> = ({
  value = [],
  setValue,
  options,
}) => (
  <Stack
    maxH="200px"
    overflowY="scroll"
    w="full"
    sx={useColorModeValue(scrollShadow, undefined)} // TODO: dark mode shadows
  >
    {options?.map(
      ({ id = '', label }) =>
        isStringId(id) && (
          <Checkbox
            p="0"
            key={id}
            borderRadius="4px"
            isChecked={(id && value.includes(id)) || false}
            sx={{
              '.chakra-checkbox__control': {
                borderRadius: '4px',
                borderColor: COLORS.GRAY.GRAY_500,
                borderWidth: '1px',
              },
              '.chakra-checkbox__control[data-checked]': {
                bg: COLORS.FINCH.PURPLE,
                borderColor: COLORS.FINCH.PURPLE,
              },
            }}
            onChange={(e) => {
              if (e.target.checked) {
                setValue([...value, id]);
              } else {
                setValue(
                  value.filter((selectedFilter) => selectedFilter !== id),
                );
              }
            }}
          >
            {label}
          </Checkbox>
        ),
    )}
  </Stack>
);

export const RadioFilterInputForArrayId: FilterInput<
  RadioFilterWithArrayIdValue
> = ({ value, setValue, options }) => (
  <RadioGroup
    value={value?.join('-') || ''}
    onChange={(val) => setValue(val.split('-'))}
    maxH="200px"
    overflowY="scroll"
    w="full"
    sx={scrollShadow}
  >
    <Stack>
      {options?.map(
        ({ id, label }) =>
          isArrayId(id) && (
            <Radio
              p="0"
              isChecked={false}
              key={id?.join('-')}
              value={id?.join('-')}
            >
              {label}
            </Radio>
          ),
      )}
    </Stack>
  </RadioGroup>
);

export const DateRangeFilterInput: FilterInput<DateFilterValue> = ({
  value,
  setValue,
  options,
}) => {
  const pastDate = value?.range?.[0] ? value?.range?.[0] : undefined;
  const futureDate = value?.range?.[1] ? value?.range?.[1] : undefined;

  return (
    <>
      <RadioGroup
        value={value?.id || ''}
        onChange={(val) =>
          setValue({
            id: val as DateRange,
            range: getTimeValueForSelectedDateRange(val as DateRange),
          })
        }
      >
        <Stack>
          {options?.map(
            ({ id, label }) =>
              isStringId(id) && (
                <Radio p="0" isChecked={false} key={id} value={id}>
                  {label}
                </Radio>
              ),
          )}
        </Stack>
      </RadioGroup>

      {value?.id === 'custom_range' && (
        <Stack>
          <Stack spacing="1">
            <Text fontSize="sm">Start Date</Text>
            <ReactDatePicker
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
              selected={pastDate ? new Date(pastDate) : undefined}
              maxDate={futureDate ? new Date(futureDate) : new Date()}
              onChange={(date) => {
                const val = date ? new Date(date?.toLocaleString()) : undefined;
                setValue({
                  id: 'custom_range',
                  range: [
                    val?.getTime() || new Date().getTime(),
                    futureDate || new Date().getTime(),
                  ],
                });
              }}
              customInput={<Input />}
              calendarContainer={CalendarContainer}
            />
          </Stack>
          <Stack spacing="1">
            <Text fontSize="sm">End Date</Text>
            <ReactDatePicker
              placeholderText="MM/DD/YYYY"
              dateFormat="MM/dd/yyyy"
              selected={futureDate ? new Date(futureDate) : undefined}
              maxDate={new Date()}
              minDate={pastDate ? new Date(pastDate) : undefined}
              onChange={(date) => {
                const val = date ? new Date(date?.toLocaleString()) : undefined;
                setValue({
                  id: 'custom_range',
                  range: [
                    pastDate || new Date().getTime(),
                    val?.getTime() || new Date().getTime(),
                  ],
                });
              }}
              customInput={<Input />}
              calendarContainer={CalendarContainer}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};

export const RelativeDateFilterInput: FilterInput<RelativeDateFilterValue> = ({
  value,
  setValue,
  options,
}) => {
  const units = value?.units ?? 'hours';
  const amount = value?.amount;
  const direction = value?.id ?? 'more';
  const directionOptions = useMemo(
    () =>
      uniqBy(
        options?.map(({ id, label }) => ({
          id: isArrayId(id) ? (head(id) as string) : id,
          label,
        })) ?? [],
        'label',
      ),
    [],
  );
  const unitOptions = useMemo(
    () =>
      Array.from(
        new Set(
          map(options, 'id').map((x) =>
            isArrayId(x) ? (last(x) as string) : x,
          ),
        ),
      ),
    [],
  );

  return (
    <HStack>
      <Menu variant="secondary">
        <MenuButton
          as={Button}
          borderRadius="lg"
          border="1px solid"
          borderColor={COLORS.GRAY.GRAY_400}
          background={COLORS.WHITE}
          padding="10px"
          rightIcon={<ChevronDownIcon />}
          minWidth="124px"
          _active={{ background: 'none', borderColor: COLORS.FINCH.PURPLE }}
          _hover={{ textDecoration: 'unset' }}
          color={COLORS.FINCH.BLACK}
          fontSize="14px"
          textAlign="left"
        >
          {options?.find(({ id }) => id[0] === direction)?.label ?? ''}
        </MenuButton>
        <MenuList padding="4px">
          <MenuOptionGroup
            defaultValue="organization"
            type="radio"
            onChange={(id) => {
              setValue({
                amount: amount ?? 0,
                units,
                id: id as RelativeDateRange,
              });
            }}
          >
            {directionOptions?.map(({ id, label }) => (
              <MenuItemOption key={id} value={id} icon={null} borderRadius="sm">
                <HStack>
                  <Text>{label}</Text>
                  <Spacer />
                  {id === direction ? <CheckIcon /> : null}
                </HStack>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      {direction !== 'never' && (
        <>
          <NumberInput
            defaultValue={amount}
            min={0}
            max={365}
            maxWidth="82px"
            onChange={debounce(
              (_, value: number) =>
                setValue({
                  amount: value,
                  units,
                  id: direction,
                }),
              400,
            )}
          >
            <NumberInputField
              placeholder="10"
              border="1px solid"
              borderRadius="lg"
              borderColor={COLORS.GRAY.GRAY_400}
              background={COLORS.WHITE}
            ></NumberInputField>
          </NumberInput>
          <Menu variant="secondary">
            <MenuButton
              as={Button}
              borderRadius="lg"
              border="1px solid"
              borderColor={COLORS.GRAY.GRAY_400}
              background={COLORS.WHITE}
              padding="10px"
              rightIcon={<ChevronDownIcon />}
              minWidth="108px"
              _active={{ background: 'none', borderColor: COLORS.FINCH.PURPLE }}
              _hover={{ textDecoration: 'unset' }}
              color={COLORS.FINCH.BLACK}
              fontSize="14px"
              textAlign="left"
            >
              {unitOptions.find((x) => x === units) ?? ''}
            </MenuButton>
            <MenuList padding="4px">
              <MenuOptionGroup
                defaultValue="organization"
                type="radio"
                onChange={(x) => {
                  setValue({
                    amount: amount ?? 0,
                    units: x as RelativeDateUnit,
                    id: direction,
                  });
                }}
              >
                {unitOptions.map((label) => (
                  <MenuItemOption
                    key={label}
                    value={label}
                    icon={null}
                    borderRadius="sm"
                  >
                    <HStack>
                      <Text>{label}</Text>
                      <Spacer />
                      {label === units ? <CheckIcon /> : null}
                    </HStack>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </>
      )}
    </HStack>
  );
};

export const RadioFilterInput: FilterInput<RadioFilterWithSingleIdValue> = ({
  value,
  setValue,
  options,
}) => (
  <RadioGroup
    value={value || ''}
    onChange={setValue}
    maxH="200px"
    overflowY="scroll"
    w="full"
    sx={scrollShadow}
  >
    <Stack>
      {options?.map(
        ({ id, label }) =>
          isStringId(id) && (
            <Radio isChecked={false} p="0" key={id} value={id}>
              {label}
            </Radio>
          ),
      )}
    </Stack>
  </RadioGroup>
);
