import { ReactNode } from 'react';

import { COLORS } from '../../constant';

import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { StagedConnectionDetail } from '../types';
import { ConnectionDetailTab, connectionDetailTabs } from '../constants';

import { ConnectSessions } from './ConnectSessions';
import { ConnectionOverviewPanel } from './ConnectionOverviewPanel';

const TAB_TO_COMPONENT: Record<
  ConnectionDetailTab,
  (props: { connectionDetail: StagedConnectionDetail }) => ReactNode
> = {
  [ConnectionDetailTab.OVERVIEW]: ({
    connectionDetail,
  }: {
    connectionDetail: StagedConnectionDetail;
  }) => <ConnectionOverviewPanel connectionDetail={connectionDetail} />,
  [ConnectionDetailTab.CONNECT_SESSIONS]: ({
    connectionDetail,
  }: {
    connectionDetail: StagedConnectionDetail;
  }) => <ConnectSessions connectionDetail={connectionDetail} />,
};

const ConnectionDetailTabs = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail;
}) => {
  const tabs = Object.entries(connectionDetailTabs);

  return (
    <Tabs flexGrow={1}>
      <TabList gap={6}>
        {tabs.map(([key, value]) => (
          <Tab
            key={key}
            fontSize="14px"
            fontWeight="semibold"
            color={COLORS.FINCH.BLACK}
            padding="0 0 12px 0"
          >
            {value}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map(([key]) => (
          <TabPanel key={key} paddingLeft="0" paddingRight="0">
            {TAB_TO_COMPONENT[parseInt(key, 10) as ConnectionDetailTab]({
              connectionDetail,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const StagedConnectionDetailContent = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail;
}) => {
  return (
    <Flex
      gap="24px"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      align={{ base: 'stretch', lg: 'flex-start' }}
    >
      <ConnectionDetailTabs connectionDetail={connectionDetail} />
    </Flex>
  );
};
