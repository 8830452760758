import { COLORS } from '../../constant';
import { Box, HStack, Text, useClipboard } from '@chakra-ui/react';
import { StagedConnectionDetail } from '../types';

import { FiCheckCircle } from 'react-icons/fi';
import { TransparentIconButton } from '../../shared/TransparentIconButton';
import { RefObject } from 'react';
import { CopyIcon } from '../../shared/icons/CopyIcon';
import { format } from '../../shared/utils/dateFormatter';

import {
  ConnectionCard,
  ConnectionCardHeader,
  ConnectionCardBody,
  ConnectionCardRow,
} from '../../components/ConnectionCard';
import { ScopeTag } from 'components/ScopeTag';

export const ClipboardButton = ({
  buttonRef,
  value,
}: {
  buttonRef?: RefObject<HTMLButtonElement>;
  value: string;
}) => {
  const { hasCopied, onCopy } = useClipboard(value);
  return hasCopied ? (
    <TransparentIconButton
      ref={buttonRef}
      aria-label="copied"
      icon={<FiCheckCircle />}
      onClick={onCopy}
      variant="icon"
      width="20px"
      height="20px"
    />
  ) : (
    <TransparentIconButton
      ref={buttonRef}
      aria-label="copy"
      icon={<CopyIcon />}
      onClick={onCopy}
      variant="icon"
      width="20px"
      height="20px"
    />
  );
};

export const ConnectionInformationCard = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail;
}) => {
  return (
    <ConnectionCard>
      <ConnectionCardHeader>
        <Text
          fontWeight="500"
          fontSize="14px"
          lineHeight="17px"
          color={COLORS.FINCH.BLACK}
        >
          Connection Information
        </Text>
      </ConnectionCardHeader>
      <ConnectionCardBody align="flex-start" spacing="4">
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Customer Name
            </Text>
          </Box>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.FINCH.BLACK}
            >
              {connectionDetail.connectSession.externalCompanyName ?? ''}
              <ClipboardButton
                value={
                  connectionDetail.connectSession.externalCompanyName ?? ''
                }
              />
            </Text>
          </Box>
        </ConnectionCardRow>
        {connectionDetail.connectSession.externalCompanyEmail && (
          <ConnectionCardRow>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.GRAY.GRAY_600}
                width="200px"
              >
                Customer Email
              </Text>
            </Box>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
              >
                {connectionDetail.connectSession.externalCompanyEmail ?? ''}
                <ClipboardButton
                  value={
                    connectionDetail.connectSession.externalCompanyEmail ?? ''
                  }
                />
              </Text>
            </Box>
          </ConnectionCardRow>
        )}
        {connectionDetail.stagedConnection.externalCompanyId && (
          <ConnectionCardRow>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.GRAY.GRAY_600}
                width="200px"
              >
                Customer ID
              </Text>
            </Box>
            <Box>
              <Text
                fontWeight={400}
                fontSize="14px"
                lineHeight="17px"
                color={COLORS.FINCH.BLACK}
              >
                {connectionDetail.stagedConnection.externalCompanyId}
                <ClipboardButton
                  value={connectionDetail.stagedConnection.externalCompanyId}
                />
              </Text>
            </Box>
          </ConnectionCardRow>
        )}
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Requested Scopes
            </Text>
          </Box>
          <Box
            fontWeight={400}
            fontSize="14px"
            lineHeight="17px"
            color={COLORS.FINCH.BLACK}
          >
            <HStack spacing="2" wrap="wrap">
              {connectionDetail.connectSession.scopes.map((type) => (
                <ScopeTag key={type} scope={type} />
              ))}
            </HStack>
          </Box>
        </ConnectionCardRow>
        <ConnectionCardRow>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.GRAY.GRAY_600}
              width="200px"
            >
              Created At
            </Text>
          </Box>
          <Box>
            <Text
              fontWeight={400}
              fontSize="14px"
              lineHeight="17px"
              color={COLORS.FINCH.BLACK}
            >
              {format(
                new Date(connectionDetail.stagedConnection.firstConnectedAt),
              )}
            </Text>
          </Box>
        </ConnectionCardRow>
      </ConnectionCardBody>
    </ConnectionCard>
  );
};
