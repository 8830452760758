import {
  Text,
  Stack,
  HStack,
  Icon,
  StackDivider,
  Center,
  useDisclosure,
} from '@chakra-ui/react';
import { GreenCheck } from 'shared/icons/GreenCheck';
import { ChevronRightIcon } from 'shared/icons/ChevronRight';
import {
  ConnectionCard,
  ConnectionCardHeader,
} from '../../components/ConnectionCard';
import { COLORS } from 'constant';
import { PillButton } from 'components/PillButton';
import { CalendarIcon } from 'shared/icons/Calendar';
import { DurationIcon } from 'shared/icons/Duration';
import { CancelCircleIcon } from 'shared/icons/CancelCircle';
import {
  ConnectSessionEvent,
  ConnectStep,
  ConnectSessionEventType,
  ConnectSessionErrorEvent,
} from 'types/connect';
import { IncompleteCircle } from 'shared/icons/IncompeleteCircle';
import { TimelineErrorDrawer } from './TimelineErrorDrawer';
import { InProgressIcon } from 'shared/icons/InProgressCircle';

interface TimelineStep {
  step: number;
  title: string;
  status: 'Not Started' | 'Completed' | 'Error' | 'In Progress';
  date: string;
  time: string;
  duration: string;
  errorCount?: number;
}

const StatusToIcon = {
  'Not Started': { icon: IncompleteCircle, color: COLORS.GRAY.GRAY_600 },
  Completed: { icon: GreenCheck, color: COLORS.GREEN },
  Error: { icon: CancelCircleIcon, color: COLORS.FINCH.RED },
  'In Progress': { icon: InProgressIcon, color: COLORS.FINCH.PURPLE },
};

const formatDuration = (ms: number): string => {
  const minutes = Math.floor(ms / 60000);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0) {
    return `${hours}h ${remainingMinutes}m`;
  } else {
    return `${minutes}m`;
  }
};

const getStepNumber = (step: string): number => {
  switch (step) {
    case ConnectStep.PREAMBLE:
      return 1;
    case ConnectStep.PERMISSIONS:
      return 2;
    case ConnectStep.SELECT_PROVIDER:
      return 3;
    default:
      return 4;
  }
};

const getStepTitle = (step: number): string => {
  switch (step) {
    case 1:
      return 'Terms of Service';
    case 2:
      return 'Permissions';
    case 3:
      return 'Provider Selection';
    case 4:
      return 'Authorization';
    default:
      return 'Unknown Step';
  }
};

const findNextKnownStep = (
  stepMap: Record<string, TimelineStep>,
  currentStep: number,
): TimelineStep | null => {
  for (let i = currentStep + 1; i <= 4; i++) {
    if (stepMap[i]) {
      return stepMap[i] as TimelineStep;
    }
  }
  return null;
};

// Add this function to generate timeline steps
const generateTimelineSteps = (
  sessionEvents: ConnectSessionEvent[],
): { steps: TimelineStep[]; errorEvents: ConnectSessionErrorEvent[] } => {
  const sortedEvents = [...sessionEvents].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  );

  const stepMap: Record<string, TimelineStep> = {};
  const authorizationErrors: ConnectSessionErrorEvent[] = [];
  let lastReachedStep = 0;
  let isSessionCompleted = false;

  sortedEvents.forEach((event) => {
    const eventDate = new Date(event.createdAt);
    const formattedDate = eventDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const formattedTime = eventDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });

    const step = getStepNumber(event.eventData.step);
    const title = getStepTitle(step);

    lastReachedStep = Math.max(lastReachedStep, step);

    if (!stepMap[step]) {
      stepMap[step] = {
        step,
        title,
        status: step < 4 ? 'Completed' : 'In Progress',
        date: formattedDate,
        time: formattedTime,
        duration: '—',
      };
    }

    if (event.eventType === ConnectSessionEventType.OnError) {
      authorizationErrors.push(event);
    } else if (event.eventType === ConnectSessionEventType.OnSessionCompleted) {
      isSessionCompleted = true;
    }
  });

  // Calculate durations between steps
  const stepNumbers = Object.keys(stepMap)
    .map(Number)
    .sort((a, b) => a - b);
  for (let i = 1; i < stepNumbers.length; i++) {
    const currentStep = stepNumbers[i] as number;
    const prevStep = stepNumbers[i - 1] as number;
    const currentStepDate = new Date(
      `${(stepMap[currentStep] as TimelineStep).date} ${
        (stepMap[currentStep] as TimelineStep).time
      }`,
    );
    const prevStepDate = new Date(
      `${(stepMap[prevStep] as TimelineStep).date} ${
        (stepMap[prevStep] as TimelineStep).time
      }`,
    );
    const duration = currentStepDate.getTime() - prevStepDate.getTime();
    (stepMap[prevStep] as TimelineStep).duration = formatDuration(duration);
  }

  // Fill in empty slots and set 'In Progress' status
  const allSteps: TimelineStep[] = [];

  for (let i = 1; i <= 4; i++) {
    if (stepMap[i]) {
      allSteps.push(stepMap[i] as TimelineStep);
    } else {
      const nextKnownStep = findNextKnownStep(stepMap, i);

      let statusToSet: TimelineStep['status'] = 'Not Started';

      // If the very first step is missing, its status is incomplete
      if (i === 1) {
        statusToSet = 'Not Started';
        // If there's a previous step but no next known step, its status is in progress
      } else if (i === lastReachedStep + 1 && !nextKnownStep) {
        statusToSet = 'In Progress';
      } else if (nextKnownStep) {
        statusToSet = 'Completed';
      } else {
        statusToSet = 'Not Started';
      }

      allSteps.push({
        step: i,
        title: getStepTitle(i),
        status: statusToSet,
        date: nextKnownStep?.date ?? '—',
        time: nextKnownStep?.time ?? '—',
        duration: nextKnownStep ? '0m' : '—',
      });
    }
  }

  // Handle the authorization step (step 4) separately
  const authStep = allSteps[3];
  if (authStep) {
    if (isSessionCompleted) {
      authStep.status = 'Completed';
    } else if (lastReachedStep >= 3) {
      authStep.status = 'In Progress';
    } else {
      authStep.status = 'Not Started';
    }
  }

  // Remove duration from the last step
  if (allSteps.length > 0) {
    (allSteps[allSteps.length - 1] as TimelineStep).duration = '—';
  }

  if (authorizationErrors.length > 0) {
    const authStep = allSteps.find((step) => step.step === 4);
    if (authStep) {
      authStep.status = 'Error';
      authStep.errorCount = authorizationErrors.length;
    }
  }

  return { steps: allSteps, errorEvents: authorizationErrors };
};

export const ConnectionTimelineCard = ({
  sessionEvents,
}: {
  sessionEvents: ConnectSessionEvent[];
}) => {
  const { steps, errorEvents } = generateTimelineSteps(sessionEvents);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ConnectionCard>
      <ConnectionCardHeader>
        <Text
          fontWeight="500"
          fontSize="14px"
          lineHeight="17px"
          color={COLORS.FINCH.BLACK}
        >
          Connect Session Timeline
        </Text>
      </ConnectionCardHeader>
      <Stack
        divider={<StackDivider borderColor="gray.200" />}
        align="flex-start"
        direction={{ base: 'column', lg: 'row' }}
        spacing="16px"
      >
        {steps.map((step) => (
          <Stack
            key={step.step}
            flex={1}
            px={6}
            py={4}
            spacing={6}
            width="100%"
          >
            <Stack spacing={2}>
              <Text fontSize="xs" color={COLORS.GRAY.GRAY_600}>
                Step {step.step}
              </Text>
              <Text fontWeight="500" fontSize="sm" color={COLORS.FINCH.BLACK}>
                {step.title}
              </Text>
            </Stack>
            <Stack
              spacing={3}
              direction={{ base: 'row', lg: 'column' }}
              wrap="wrap"
              justify="space-between"
            >
              <HStack color={StatusToIcon[step.status].color}>
                <Icon as={StatusToIcon[step.status].icon} />
                <Text fontSize="sm">{step.status}</Text>
                {step.errorCount && (
                  <Center
                    bg={COLORS.GRAY.GRAY_300}
                    color={COLORS.GRAY.GRAY_800}
                    p="1"
                    minW="20px"
                    rounded="md"
                    fontWeight="500"
                    fontSize="xs"
                    lineHeight="12px"
                  >
                    {step.errorCount}
                  </Center>
                )}
              </HStack>
              <HStack>
                <CalendarIcon />
                <Text color={COLORS.GRAY.GRAY_600} fontSize="sm">
                  {step.date !== '—' ? `${step.date}, ${step.time}` : '—'}
                </Text>
              </HStack>
              <HStack>
                <DurationIcon />
                <Text color={COLORS.GRAY.GRAY_600} fontSize="sm">
                  {step.duration}
                </Text>
              </HStack>
            </Stack>
            {step.errorCount && (
              <PillButton
                alignSelf="flex-end"
                variant="secondary"
                py="1"
                px="2"
                onClick={onOpen}
                pr="1"
                rightIcon={<ChevronRightIcon />}
              >
                More info
              </PillButton>
            )}
          </Stack>
        ))}
      </Stack>
      <TimelineErrorDrawer
        errorEvents={errorEvents}
        isOpen={isOpen}
        onClose={onClose}
      />
    </ConnectionCard>
  );
};
