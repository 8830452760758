import { ConnectionCardRow } from 'components/ConnectionCard';
import {
  Box,
  DrawerCloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Stack,
  Text,
  StackDivider,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { COLORS } from '../../constant';

import { ClipboardButton } from './ConnectionInformationCard';
import { format } from '../../shared/utils/dateFormatter';
import { ConnectSessionErrorEvent, ConnectStep } from 'types/connect';
import { ReactNode } from 'react';
import { ConnectStepMap } from 'connection-detail-v2/components/SessionDetailsDrawer';

const SessionDetailItem = ({
  data,
  withClipboard,
  title,
}: {
  data: ReactNode;
  title: string;
  withClipboard?: boolean;
}) => {
  return (
    <ConnectionCardRow px="0">
      <Text
        fontWeight={400}
        flex="3"
        fontSize="14px"
        lineHeight="17px"
        color={COLORS.GRAY.GRAY_600}
        width="200px"
      >
        {title}
      </Text>

      <Box flex="5">
        {typeof data === 'string' || typeof data === 'number' ? (
          <Text
            fontWeight={400}
            fontSize="14px"
            lineHeight="17px"
            color={COLORS.FINCH.BLACK}
          >
            {data}
            {withClipboard && <ClipboardButton value={data.toString()} />}
          </Text>
        ) : (
          data
        )}
      </Box>
    </ConnectionCardRow>
  );
};

export function TimelineErrorDrawer({
  errorEvents,
  isOpen,
  onClose,
  isLoading,
}: {
  errorEvents: ConnectSessionErrorEvent[];
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
}) {
  const latestError = errorEvents[errorEvents.length - 1];
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent bg={COLORS.GRAY.GRAY_100}>
        <HStack
          p="4"
          justify="space-between"
          bg={COLORS.GRAY.GRAY_100}
          align="center"
          borderBottom={`1px solid ${COLORS.GRAY.GRAY_400}`}
        >
          <Heading fontWeight="600" fontSize="14px">
            Step 4 - Authentication
          </Heading>
          <DrawerCloseButton pos="relative" top="0" right="0" />
        </HStack>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Stack
            spacing="6"
            p="4"
            divider={<StackDivider borderColor={COLORS.GRAY.GRAY_300} />}
          >
            <SessionDetailItem title="Status" data="Error" />

            <SessionDetailItem
              title="Authentication Progress"
              data={ConnectStepMap[latestError?.eventData.step as ConnectStep]}
            />

            <SessionDetailItem
              title="Error Message"
              data={latestError?.eventData.errorMessage}
            />

            <SessionDetailItem
              title="Failed Attempts"
              data={errorEvents.length}
            />

            {latestError?.createdAt && (
              <SessionDetailItem
                title="Latest Attempt"
                data={format(new Date(latestError?.createdAt))}
              />
            )}
          </Stack>
        )}
      </DrawerContent>
    </Drawer>
  );
}
