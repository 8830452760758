import { Box, Center, Tag, Tooltip, Text, TextProps } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ConnectionCategory } from '@finch-api/common/dist/external/dashboard/connection-status';
import { COLORS } from '../../constant';
import { Code } from '../../components/Code/Code';
import { ReactNode } from 'react';
import { AssistedIcon } from '../../shared/AssistedIcon';
import { AutomatedIcon } from '../../shared/AutomatedIcon';
import { ClipboardButton } from 'components/ClipboardButton';

const TableText = ({ children, ...props }: TextProps) => {
  return (
    <Text
      width="100%"
      fontWeight={400}
      fontSize="14px"
      lineHeight="18px"
      cursor="inherit"
      color={COLORS.FINCH.BLACK}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      {...props}
    >
      {children}
    </Text>
  );
};

const CodeBlock = styled(Code)`
  color: ${COLORS.FINCH.BLACK};
  display: flex;
  gap: 8px;
`;

const connectionCategoryToIdCellInfoMap: Record<
  ConnectionCategory,
  { label: string; icon: ReactNode }
> = {
  automated: {
    label: 'Automated Connection',
    icon: <AutomatedIcon />,
  },
  assisted: {
    label: 'Assisted Connection',
    icon: <AssistedIcon />,
  },
};

export const CellWithClipboard = ({
  text,
  clipboardValue,
  textProps,
}: {
  text: string;
  textProps?: TextProps;
  clipboardValue: string;
}) => {
  return (
    <CodeBlock>
      <Center gap="2">
        <TableText {...textProps}>{text}</TableText>
        <ClipboardButton value={clipboardValue} />
      </Center>
    </CodeBlock>
  );
};

export const IdCell = ({
  id,
  connectionCategory = ConnectionCategory.Automated,
  batchedIndividuals,
}: {
  id: string;
  connectionCategory?: ConnectionCategory;
  batchedIndividuals?: number;
}) => {
  return (
    <CodeBlock>
      <Center gap="2">
        <Tooltip
          placement="top-start"
          label={connectionCategoryToIdCellInfoMap[connectionCategory].label}
        >
          <div>
            {connectionCategoryToIdCellInfoMap[connectionCategory].icon}
          </div>
        </Tooltip>

        {id}

        {batchedIndividuals && (
          <Tooltip
            placement="top-start"
            label={
              batchedIndividuals > 1
                ? `This job includes a batch request for ${batchedIndividuals} individuals`
                : `This job includes a request for ${batchedIndividuals} individual`
            }
          >
            <Tag
              size="sm"
              fontWeight="600"
              bg={COLORS.GRAY.GRAY_300}
              color={COLORS.FINCH.GRAY}
              px="5px"
              minW={0}
            >
              {batchedIndividuals}
            </Tag>
          </Tooltip>
        )}
      </Center>
    </CodeBlock>
  );
};

export const TextCell = ({ text, ...props }: TextProps & { text: string }) => {
  return (
    <Box>
      <TableText title={text} {...props}>
        {text}
      </TableText>
    </Box>
  );
};
