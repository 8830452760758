import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import {
  NewSession,
  ReauthSession,
} from '../components/ConnectSession/ModalNewConnectSession';
import { baseURL } from '../../utils/baseUrls';
import { useApplication } from '../../applications/hooks';
import uniqBy from 'lodash/uniqBy';
import startCase from 'lodash/startCase';
import { PRODUCT_LABEL_MAP } from '../../constant/scopes';
import { useSandboxStore } from '../state/sandboxStore';
import {
  CreateConnectSessionError,
  CreateConnectSessionResponse,
} from 'types/connect';

export type Scope = { key: string; label: string; selected: boolean };

const mapScopes = (formScopes: Scope[]) => {
  return formScopes.filter((scope) => scope.selected).map(({ key }) => key);
};

export const useConnectSession = () => {
  const toast = useToast();
  const { applicationId } = useParams<{
    applicationId: string;
    connectionId: string;
  }>();
  const { getAccessTokenSilently } = useAuth0();
  const { application } = useApplication(applicationId);
  const selectedSandbox = useSandboxStore((store) => store.selectedSandbox);

  const scopes = uniqBy(
    application?.scopes
      .filter(({ name }) => name && PRODUCT_LABEL_MAP.get(name))
      .map(({ name }) => ({
        key: `employer:${name}`,
        selected: true,
        label: PRODUCT_LABEL_MAP.get(name) || startCase(name),
      })) || [],
    'key',
  );

  const create = useMutation<
    CreateConnectSessionResponse,
    AxiosError<CreateConnectSessionError>,
    NewSession | ReauthSession
  >({
    mutationFn: async (session: NewSession | ReauthSession) => {
      const uri = new URL('/api/v1/connect-session', baseURL);

      return axios
        .post(
          uri.toString(),
          {
            ...session,
            scopes: mapScopes(scopes),
            clientId: applicationId,
            sandbox: application?.isSandbox() ? selectedSandbox : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => res.data);
    },
    onSuccess: () => {
      toast({
        title: 'Finch Connect URL created successfully',
        status: 'success',
        isClosable: true,
      });
    },
  });

  return { create };
};
