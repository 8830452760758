import { Box, Stack } from '@chakra-ui/react';
import { StagedConnectionDetail } from '../types';

import { ConnectionInformationCard } from './ConnectionInformationCard';
import { ConnectionTimelineCard } from './ConnectionTimelineCard';
import { ProviderInformationCard } from './ProviderInformationCard';

export const ConnectionOverviewPanel = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail;
}) => {
  return (
    <Box width="100%">
      <Stack spacing="24px">
        <Stack direction="column" spacing="24px" flex={1}>
          <ConnectionTimelineCard
            sessionEvents={connectionDetail.connectSessionEvents}
          />
        </Stack>
        <Stack
          spacing="24px"
          direction={{ base: 'column', md: 'row' }}
          flex={1}
        >
          <Box flexBasis="65%">
            <ConnectionInformationCard connectionDetail={connectionDetail} />
          </Box>

          <Stack flexBasis="35%" justify="stretch">
            <ProviderInformationCard connectionDetail={connectionDetail} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
