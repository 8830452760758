export const InProgressIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 12V10.5H2.04167C1.41667 9.94444 0.920139 9.28125 0.552083 8.51042C0.184028 7.73958 0 6.90278 0 6C0 4.59722 0.423611 3.36458 1.27083 2.30208C2.11806 1.23958 3.19444 0.541667 4.5 0.208333V1.77083C3.625 2.09028 2.90625 2.62847 2.34375 3.38542C1.78125 4.14236 1.5 5.01389 1.5 6C1.5 6.66667 1.63542 7.28472 1.90625 7.85417C2.17708 8.42361 2.54167 8.90972 3 9.3125V8H4.5V12H0.5ZM7.5 11.7917V10.2292C8.375 9.90972 9.09375 9.37153 9.65625 8.61458C10.2188 7.85764 10.5 6.98611 10.5 6C10.5 5.33333 10.3646 4.71528 10.0938 4.14583C9.82292 3.57639 9.45833 3.09028 9 2.6875V4H7.5V0H11.5V1.5H9.95833C10.5833 2.05556 11.0799 2.71875 11.4479 3.48958C11.816 4.26042 12 5.09722 12 6C12 7.40278 11.5764 8.63542 10.7292 9.69792C9.88194 10.7604 8.80556 11.4583 7.5 11.7917Z"
      fill="#3E4AE7"
    />
  </svg>
);
