import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Table,
  Tbody,
  Tr,
  Td,
  Box,
  TableContainer,
  Stack,
} from '@chakra-ui/react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Row,
  ColumnFiltersState,
  SortingState,
  PaginationState,
} from '@tanstack/react-table';

import { Pagination } from '../../components/Pagination';
import {
  CompanyCell,
  ConnectStepCell,
  DateCell,
  IdCell,
  ProviderNameCell,
} from './TableCells';
import { EmptyStateMessage } from './EmptyStateConnectionsTable';
import { COLORS, SHADOWS } from '../../constant';
import { ConnectionCategory } from '@finch-api/common/dist/external/dashboard/connection-status';
import { Loading } from '../../components/Loading/Loading';
import { StagedConnection, StagedConnectionTableFilterId } from '../types';
import {
  TableFilters,
  SearchFilterInput,
  filterFns,
  DateRangeFilterInput,
  EmptyFilterStateMessage,
  dateRangeOptions,
  SelectFilterInput,
} from '../../components/TableFilters';
import {
  connectStepOptions,
  getProviderOptions,
  stagedConnectionFilterList,
} from '../constants';
import { useFilters } from '../../components/TableFilters/hooks';
import { useSearchParams } from '../../shared/useQuery';
import {
  getConnectionFilterFromQueryParams,
  useConnectionsQuerySync,
} from '../hooks/useConnectionsQuerySync';
import { TableHeaderContent } from '../../components/TableHeaderContent';
import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { ConnectionsFetchErrorMessage } from './ConnectionsFetchErrorMessage';

const Style = styled.div`
  table {
    border-spacing: 0;

    th {
      border: 0px;
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};
    }

    tr {
      border-bottom: 1px solid ${COLORS.GRAY.GRAY_400};

      :last-child {
        border-bottom: 0px;
      }
    }

    td {
      padding: 10px 20px;
      border: 0px;
    }
  }
`;

const TableCellText = styled.div<{ width: string | undefined }>`
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.width && `width: ${props.width};`}
`;

const StagedTableBodyContent = ({
  applicationId,
  rows,
}: {
  applicationId: string;
  rows: Row<StagedConnection>[];
}) => {
  const history = useHistory();

  return (
    <>
      {rows.map((row) => {
        return (
          <Tr
            cursor="pointer"
            _hover={{
              bgColor: COLORS.GRAY.GRAY_100,
            }}
            key={row.id}
          >
            {row.getVisibleCells().map((cell) => (
              <Td
                key={cell.id}
                style={cell.column.columnDef.meta?.style}
                onClick={() => {
                  history.push(
                    `/app/applications/${applicationId}/incomplete-connections/${row.original.id}`,
                    { navigatedFromConnectionsTable: true },
                  );
                }}
              >
                <TableCellText
                  width={cell.column.columnDef.meta?.style?.minWidth?.toString()}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellText>
              </Td>
            ))}
          </Tr>
        );
      })}
    </>
  );
};

export const implementationKindToCategory: Record<
  ImplementationKind,
  ConnectionCategory
> = {
  api_token: ConnectionCategory.Automated,
  oauth: ConnectionCategory.Automated,
  credential: ConnectionCategory.Automated,
  assisted: ConnectionCategory.Assisted,

  finch_sandbox_api_token: ConnectionCategory.Automated,
  finch_sandbox_oauth: ConnectionCategory.Automated,
  finch_sandbox_credential: ConnectionCategory.Automated,
  finch_sandbox_assisted: ConnectionCategory.Assisted,
};

export const StagedConnectionsTable = ({
  connections,
  isSandbox,
  loading,
  error,
}: {
  connections: StagedConnection[];
  isSandbox?: boolean;
  loading: boolean;
  error: Error | null;
}) => {
  const { applicationId } = useParams<{ applicationId: string }>();
  const queryParams = useSearchParams();
  const pageParam = queryParams.get('page') || '0';

  if (!applicationId) throw new Error('no application id in url param');

  const tableData = useMemo(() => connections, [connections]);

  const columnHelper = createColumnHelper<StagedConnection>();

  const columns = [
    columnHelper.accessor('externalCompanyName', {
      header: 'Company',
      meta: {
        filterInput: SearchFilterInput,
        style: { minWidth: '220px' },
      },
      cell: (props) => (
        <Box py="4">
          <CompanyCell name={props.getValue()} />
        </Box>
      ),
    }),
    columnHelper.accessor('externalCompanyId', {
      header: 'Customer ID',
      meta: {
        filterInput: SearchFilterInput,
      },
      cell: (props) => <IdCell id={props.getValue()} />,
    }),
    columnHelper.accessor('firstConnectedAt', {
      header: 'Created',
      filterFn: 'dateRange',
      meta: {
        style: { minWidth: '200px' },
        filterInput: DateRangeFilterInput,
        filterInputOptions: dateRangeOptions,
      },
      cell: (props) => <DateCell value={props.getValue()} />,
    }),
    columnHelper.accessor('providerName', {
      header: 'Provider',
      filterFn: 'arrIncludesSome',
      meta: {
        filterInput: SelectFilterInput,
        filterInputOptions: getProviderOptions(tableData),
        filterLabel: 'Provider',
        style: { minWidth: '200px' },
      },
      cell: (props) =>
        props.row.original.providerIcon ? (
          <ProviderNameCell
            name={props.getValue()}
            icon={props.row.original.providerIcon}
          />
        ) : (
          '-'
        ),
      sortingFn: (rowA, rowB) => {
        return rowA.original.providerName > rowB.original.providerName
          ? 1
          : rowA.original.providerName < rowB.original.providerName
          ? -1
          : 0;
      },
    }),
    columnHelper.accessor('latestStepInfo', {
      header: 'Connect Step Reached',
      filterFn: 'equals',
      meta: {
        style: { minWidth: '200px' },
        filterInput: SelectFilterInput,
        filterInputOptions: connectStepOptions,
      },
      cell: (props) => <ConnectStepCell props={props} />,
    }),
  ];

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'firstConnectedAt',
      desc: true,
    },
  ]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: parseInt(pageParam),
    pageSize: 10,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() =>
    getConnectionFilterFromQueryParams(),
  );

  const [selectedFilters, setSelectedFilters] = useState<
    StagedConnectionTableFilterId[]
  >(
    () =>
      getConnectionFilterFromQueryParams().map(
        ({ id }) => id,
      ) as StagedConnectionTableFilterId[],
  );

  const {
    getHeaderGroups,
    getState,
    getRowModel,
    getColumn,

    getCanPreviousPage,
    getCanNextPage,
    getPageCount,

    getFilteredRowModel: getCurrentFilteredRowModel,
  } = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnFilters,
      pagination,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    filterFns,
  });

  useEffect(() => {
    if (!loading && connections) {
      const maxPageIndex = getPageCount() - 1;
      const pageIndexParam = parseInt(pageParam);
      const newPageIndex =
        pageIndexParam <= maxPageIndex ? pageIndexParam : maxPageIndex;

      setPagination({
        pageIndex: newPageIndex > 0 ? newPageIndex : 0,
        pageSize,
      });
    }
  }, [connections, getPageCount, loading, pageParam, pageSize]);

  const history = useHistory();

  useEffect(() => {
    const currentSearch = window.location.search;
    const newUrlParams = new URLSearchParams(currentSearch);

    newUrlParams.set('page', pageIndex.toString());

    history.replace(`?${newUrlParams}`);
  }, [history, applicationId, pageIndex]);

  const state = getState();
  const rows = getRowModel().rows;

  const shouldShowEmptyState = !loading && 0 === connections?.length && !error;

  const shouldShowEmptyRowsState =
    !loading && 0 === rows.length && 0 < state.columnFilters.length && !error;
  const shouldShowPagination = 0 < rows.length && !shouldShowEmptyState;

  const resetPagination = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  };

  const { setFilterValue, removeAllFilters, ...filterProps } = useFilters<
    StagedConnection,
    StagedConnectionTableFilterId
  >({
    filterList: [...stagedConnectionFilterList],
    getColumn,
    selectedFilters,
    setSelectedFilters,
  });

  useConnectionsQuerySync({ columnFilters });

  return (
    <>
      <Stack
        borderRadius="8px"
        boxShadow={SHADOWS.PAGE}
        spacing="0"
        backgroundColor={COLORS.WHITE}
      >
        <TableFilters
          setFilterValue={(...args) => {
            setFilterValue(...args);

            // Reset back to the first page after changing filters from the menu.
            // This is to prevent 0 search results showing if the new filtered list
            // size is less than the last filtered size.
            resetPagination();
          }}
          {...filterProps}
        />

        <TableContainer>
          <Style>
            <Table
              borderTop={`1px solid ${COLORS.GRAY.GRAY_400}`}
              variant="simple"
            >
              <TableHeaderContent
                headerGroups={getHeaderGroups()}
                thOverrideStyle={{ p: '10px 20px' }}
              />
              <Tbody textColor="#1A202C">
                {!loading && (
                  <StagedTableBodyContent
                    applicationId={applicationId}
                    rows={rows}
                  />
                )}
              </Tbody>
            </Table>
          </Style>
          {error && (
            <ConnectionsFetchErrorMessage
              table="connections"
              onRetry={() => window.location.reload()}
            />
          )}
          {shouldShowEmptyState && (
            <EmptyStateMessage omitExtendedCaption={isSandbox} />
          )}
          {shouldShowEmptyRowsState && (
            <EmptyFilterStateMessage
              table="connections"
              onClearFilters={removeAllFilters}
            />
          )}
        </TableContainer>
        {loading && (
          <Loading message="We're fetching your application's incomplete employer connections." />
        )}
      </Stack>
      {shouldShowPagination && (
        <Box paddingTop={2}>
          <Pagination
            previousPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex - 1,
              }));
            }}
            canPreviousPage={getCanPreviousPage()}
            nextPage={() => {
              setPagination((prev) => ({
                ...prev,
                pageIndex: prev.pageIndex + 1,
              }));
            }}
            canNextPage={getCanNextPage()}
            pageIndex={state.pagination.pageIndex}
            pageSize={state.pagination.pageSize}
            totalSize={getCurrentFilteredRowModel().rows.length}
          />
        </Box>
      )}
    </>
  );
};
